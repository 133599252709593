<template>
  <div>
    <b-row>
      <b-col cols="12">
        <h4 class="text-primary text-center">
          <i class="fa-solid fa-smtp"></i>
          {{ $t('tabs.smtps.details') }}
        </h4>
      </b-col>
      <b-col cols="12" xl="12" class="mb-1">
        <label>{{ $t('columns.user') }}</label>
        <user-select-box v-model="smtp.userId"/>
      </b-col>

      <b-col cols="12" xl="12">
        <label>{{ $t('columns.orgId') }}</label>
        <organization-select-box v-model="smtp.orgId"/>
      </b-col>


      <b-col cols="12" xl="12" class="mb-1">
        <label>{{ $t('columns.provider') }}</label>
        <v-select
            v-model="smtp.provider"
            :options="$store.getters['data/allSmtpProviders']"
        />
      </b-col>

      <b-col cols="12" xl="12" class="mb-1">
        <label>{{ $t('columns.email') }}</label>
        <b-form-input v-model="smtp.email"/>
      </b-col>

      <b-col cols="12" xl="12" class="mb-1">
        <label>{{ $t('columns.password') }}</label>
        <b-form-input v-model="smtp.password"/>
      </b-col>


    </b-row>
    <div class="text-center mt-2" style="position: absolute;bottom: 10px; width: 100%">
      <b-button class="d-inline-block" size="sm" variant="outline-warning" >{{$t('operations.reset')}}</b-button>&nbsp;
      <b-button class="d-inline-block" size="sm" variant="outline-success" @click="save">{{$t('operations.save')}}</b-button>
    </div>
  </div>
</template>

<script>
import Smtp from '@/model/smtp/Smtp'
import mixinPermissions from '@/components/mixin/mixinPermissions'
import { mapActions, mapGetters } from 'vuex'
import mixinNotifications from '@/components/mixin/mixinNotifications'
import OrganizationSelectBox from '@/components/widget/select/OrganizationSelectBox'
import BusinessUnitSelectBox from '@/components/widget/select/BusinessUnitSelectBox'
import CountriesSelectBox from '@/components/widget/select/CountriesSelectBox'
import CalendarPicker from '@/components/widget/picker/calendarPicker'
import UserSelectBox from '@/components/widget/select/UserSelectBox';

export default {
  name: 'SmtpEditDetails',
  components: { UserSelectBox, CalendarPicker, CountriesSelectBox, BusinessUnitSelectBox, OrganizationSelectBox },
  mixins: [mixinPermissions, mixinNotifications],
  data: ()=>({
    smtp: Smtp(),
  }),
  props: {
    value: {
      required: true,
      default: () => ({})
    },
    newSmtp: {
      type: Boolean,
      default: () => false
    }
  },
  watch:{
    value:{
      handler(){
        this.smtp = Object.assign(Smtp(), this.value)
      },
      immediate: true
    }
  },
  computed:{
   ...mapGetters('smtp', [''])
  },
  methods:{
    ...mapActions('smtp', ['updateSmtp','createSmtp']),

    async save() {
      if (this.smtp.id == null) {
        await this.addSmtp();
      } else {
        await this.modifySmtp();
      }
    },

    async addSmtp() {
      let $this = this;
      await this.createSmtp({
        smtp: this.smtp
      })
          .catch(err => {
            this.showErrorNotification(this.$t('globalMessages.fetchDataError', { system: this.$t('menu.smtps') }), err.response.data.error);
          });
      this.showSuccessNotification($this.$t('globalMessages.addDataSuccess', { system: $this.$t('menu.smtps') }));
      this.$emit('input', this.smtp);
      this.$emit('close');

    },
    async modifySmtp() {
      let $this = this;

      await this.updateSmtp({
        smtp: this.smtp,
        id: this.smtp.id
      })
          .catch(err => {
            $this.showErrorNotification($this.$t('globalMessages.fetchDataError', { system: $this.$t('menu.smtps') }), err.response.data.error);
          });
      this.showSuccessNotification($this.$t('globalMessages.updateDataSuccess', { system: $this.$t('menu.smtps') }));
      this.$emit('input', this.smtp);
      this.$emit('close');

    },
  }
}
</script>

<style scoped>

</style>
