export default function (other) {
  other = other || {}
  return {
    id: other.id || null,
    creationTime: other.creationTime || null,
    email: other.email || null,
    orgId: other.orgId || null,
    userId: other.userId || null,
    provider: other.provider || null,
    password: other.password || null,
  }
}
